const configVO = () => {
  return {
    id: '',
    ikey: '',
    instanceIds: '',
    name: '',
    pointType: '',
    style: '',
    technologyId: null,
    department: {
      id: ''
    },
    idsMappings: [],
  }
}

export default configVO

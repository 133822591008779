<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">工艺挂点管理</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
        v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE"
      >新增
      </a-button
      >
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="挂点名称">
              <a-input v-model="searchParams.name" placeholder="请输入挂点名称" allowClear/>
            </a-form-item>
            <a-form-item label="挂点key">
              <a-input v-model="searchParams.ikey" placeholder="请输入挂点key" allowClear/>
            </a-form-item>
            <a-form-item label="类型">
              <a-select allowClear placeholder="请选择类型" v-model="searchParams.pointType" style="width: 120px">
                <a-select-option v-for="item in pointType" :value="item.value" :key="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属工艺">
              <a-select  show-search   option-filter-prop="children"
                          allowClear placeholder="请选择工艺" v-model="searchParams.technologyId">
                <a-select-option v-for="item in technologyList" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                allowClear
                :treeData="orgTree"
                v-model="searchParams.departmentId"
                placeholder="请选择部门"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        @change="tableChange"
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :customRow="handleView"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="pointType" slot-scope="pointType">
          {{ pointType ==='point'?'点':'盒' }}
        </template>
        <span slot="action" slot-scope="text, record">
           <a @click="handleEquipment(record.id)" @click.stop>添加指标</a>
             <a-divider type="vertical"/>
          <a @click="handleEdit(record.id)" @click.stop v-auth="auth_equipment2.MONITORING_POINT_ADD_UPDATE">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" @click.stop v-auth="auth_equipment2.MONITORING_POINT_DELETE">删除</a>
        </span>
      </a-table>
    </a-card>
    <a-modal v-model="addModal" title="配置">
      <Form ref="addRef" type="add" @addSuccess="addSuccess" :technologyList="technologyList" @addError="addError"></Form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <!--        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"-->
        <!--        >保存复制-->
        <!--        </a-button>-->
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal" title="配置编辑">
      <Form
        ref="editRef"
        type="edit"
        :technologyList="technologyList"
        @editSuccess="editSuccess"
        @editError="editError"
      ></Form>
    </a-modal>
    <a-drawer
      :title="title"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="450px"
      :visible="detailDrawer"
      :mask="false"
    >
      <Detail ref="viewRef" :technologyList="technologyList"></Detail>
    </a-drawer>
    <a-drawer
      title="选择设备"
      placement="right"
      :closable="true"
      @close="deviceClose"
      width="800"
      :visible="deviceDrawer"
      :maskClosable="false"
    >
      <monitoringPointTree @selectedTreeNode="selectEquipmentData"></monitoringPointTree>
<!--      <select-device-list ref="deviceRef" @selectEquipmentData="selectEquipmentData"></select-device-list>-->
      <a-drawer
        title="选择指标"
        placement="right"
        :closable="true"
        @close="metricsClose"
        width="800"
        :visible="metricsDrawer"
      >
        <SelectMetrics ref="metricsRef" @selectedMetricsArray="selectedMetricsArray"></SelectMetrics>
      </a-drawer>
    </a-drawer>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import Form from './Form'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import { configColumns, pointType } from './common/common'
  import Detail from './Detail'
  import SelectMetrics from '@/views/equipment2/technologyPoint/SelectMetrics'
  import configVO from './common/configVO'
  import monitoringPointTree from '@/components/monitoringPointTree/List'
  export default {
    components: { PageLayout, Form, Detail, SelectMetrics, monitoringPointTree },
    mixins: [entityCRUDWithCopy],
    name: 'List',
    data () {
      return {
        categoryId: null,
        sup_this: this,
        entityBaseUrl: SERVICE_URLS.equipment2.technologyPointInfo,
        tableData: [],
        initColumns: configColumns(),
        pointType: pointType(),
        searchParams: {
          number: '0',
          departmentId: undefined,
          name: '',
          pointType: undefined,
          technologyId: undefined,
          ikey: ''
        },
        formItem: configVO(),
        technologyList: [],
        title: '',
        orgTree: [],
        deviceDrawer: false,
        metricsDrawer: false,
        idsMapping: {
          instanceId: '',
          metricsIds: []
        }
      }
    },
    created () {
      this.getTechnologySelect()
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      getTechnologySelect () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyInfo.select,
          noTips: true,
          success: (data) => {
            this.technologyList = data.body
          }
        })
      },
      addSuccess (addType) {
        if (addType === 'copy') {
          // 表示不关闭Modal
          this.$refs.addRef.formItem.systemModule = 'facility_info'
          this.addLoading = false
        } else if (addType === 'continue') {
          this.addLoading = false
          this.emptyFormSelectList()
        } else {
          // 直接关闭Modal
          this.emptyFormSelectList()
          this.addLoading = false
          this.addModal = false
        }
        this.addLoading = false
        this.search()
      },
      emptyFormSelectList () {
        this.$refs.addRef.selectEquipmentList = []
        this.$refs.addRef.selectFacilityList = []
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.title = record.name
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      },
      handleEquipment (id) {
        this.deviceDrawer = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyPointInfo.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      },
      deviceClose () {
        this.deviceDrawer = false
        const id = this.formItem.id
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyPointInfo.update,
          params: { id },
          data: this.formItem,
          noTips: true,
          success: (data) => {
            console.log('保存成功')
          }
        })
      },
      selectEquipmentData (data) {
        this.idsMapping = {
          instanceId: '',
          metricsIds: []
        }
        this.metricsDrawer = true
        this.idsMapping.instanceId = data[0]
        this.$nextTick(() => {
          this.$refs.metricsRef.searchParams.pointId = data[0]
          this.$refs.metricsRef.search()
          this.selectedMetrics(data[0])
        })
      },
      // 判断是否选择的设备id在idsMappings存在 ，返显指标
      selectedMetrics (instanceId) {
        if (this.formItem.idsMappings.length > 0 && this.isSameInstanceId() === 1) {
          for (let i = 0; i < this.formItem.idsMappings.length; i++) {
              if (this.formItem.idsMappings[i].instanceId === instanceId) {
                this.$refs.metricsRef.selectedRowKeys = this.formItem.idsMappings[i].metricsIds
              }
          }
        }
      },

      selectedMetricsArray (array) {
        this.idsMapping.metricsIds = array
        this.saveIdMapping()
      },
      metricsClose () {
        this.$refs.metricsRef.selectedRowKeys = []
        this.metricsDrawer = false
      },
      saveIdMapping () {
        // 判断是否有idsMappings
        if (this.formItem.idsMappings.length > 0) {
          if (this.isSameInstanceId() === 1) {
            this.replaceMetrics()
          } else {
            if (this.idsMapping.metricsIds.length > 0) {
              this.formItem.idsMappings.push(this.idsMapping)
            }
          }
        } else {
          if (this.idsMapping.metricsIds.length > 0) {
            this.formItem.idsMappings.push(this.idsMapping)
          }
        }
      },
      // 判断是否有相同设备id
      isSameInstanceId () {
        var isTrue = 0
        for (let i = 0; i < this.formItem.idsMappings.length; i++) {
          // 判断是否存在相同的设备id
          if (this.formItem.idsMappings[i].instanceId === this.idsMapping.instanceId) {
            isTrue = 1
          }
        }
        return isTrue
      },
      // 有相同设备id合并指标
      replaceMetrics () {
        for (let i = 0; i < this.formItem.idsMappings.length; i++) {
          if (this.formItem.idsMappings[i].instanceId === this.idsMapping.instanceId) {
            const array = []
            this.formItem.idsMappings[i].metricsIds.forEach((data) => {
              array.push(data)
            })
            this.formItem.idsMappings[i].metricsIds = this.idsMapping.metricsIds
            // const metrics = []
            // new Set(this.formItem.idsMappings[i].metricsIds).forEach((data) => {
            //   metrics.push(data)
            // })
            // this.formItem.idsMappings[i].metricsIds = metrics
          }
        }
      }


    }
  }
</script>

<style scoped></style>

<template>
  <div>
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="变量名">
            <a-input style="width:150px" allow-clear v-model="searchParams.enName" placeholder="输入变量名"></a-input>
          </a-form-item>
          <a-form-item label="描述">
            <a-input style="width:200px" allow-clear v-model="searchParams.cnName" placeholder="输入描述"></a-input>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button class="m-l-6" type="primary" @click="search" icon="search">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table
      @change="tableChange"
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      :loading="tableLoading"
      bordered
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
    </a-table>
  </div>
</template>

<script>
  import { metricsColumns } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'

  export default {
    components: {},
    mixins: [entityCRUDWithCopy],
    name: 'SelectMetrics',
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.metricsRealInfoApi,
        initColumns: metricsColumns(),
        searchParams: {
          pointId: '',
          page: 0,
          size: 10,
          cnName: '',
          enName: ''
        },
        selectedRowKeys: []
      }
    },
    created () {
    },
    methods: {
      onSelectChange (selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys
        this.$emit('selectedMetricsArray', this.selectedRowKeys)
      },
      tableChange (pagination, filters, sorter) {
        this.setSearchParamsPage(pagination)
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.search()
      },
      setSearchParamsPage (pagination) {
        this.searchParams.page = pagination.current - 1
        this.searchParams.size = pagination.pageSize
      }
    }
  }
</script>

<style scoped></style>

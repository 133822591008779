<template>
  <div>
    <div class="fs-search-box">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="设施名称">
            <a-input v-model="searchParams.name" placeholder="请输入" allowClear />
          </a-form-item>
          <a-form-item label="所属部门">
            <a-tree-select
              style="width: 210px;"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgTree"
              allowClear
              placeholder="请选择"
              v-model="searchParams.departmentId"
            ></a-tree-select>
          </a-form-item>
          <a-form-item label="所在位置">
            <a-input v-model="searchParams.location" placeholder="请输入" allowClear />
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <!--                <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>-->
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
      :pagination="pagination"
      @change="tableChange"
      :rowKey="(record) => record.id"
      bordered
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="facilityType" slot-scope="text, record">
        <span v-if="record.facilityType === 0">管线</span>
        <span v-if="record.facilityType === 1">其他</span>
      </template>
      <template slot="location" slot-scope="text, record"> {{ record.area.name }}{{ record.location }} </template>
      <template slot="plotType" slot-scope="text, record">
        <span v-if="record.plotType === 'marker'">点</span>
        <span v-if="record.plotType === 'polyline'">线</span>
        <span v-if="record.plotType === 'polygon'">面</span>
      </template>
      <template slot="deviceNumber" slot-scope="text, record">
        {{ record.deviceNumber ? record.deviceNumber : '0' }}
      </template>
      <template slot="action" slot-scope="text, record">
        <a @click="handleCheck(record)">选择</a>
      </template>
    </a-table>
  </div>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { facilityColumns } from './common/common'
import entityCRUD from '../../common/mixins/entityCRUD'
export default {
  components: {},
  mixins: [entityCRUD],
  name: 'SelectDeviceList',
  props: {
    facilityId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.facilityApi,
      initColumns: facilityColumns(),
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        name: '',
        departmentId: null,
        location: '',
      },
      tableLoading: false,
      orgTree: [],
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    // 选择单个设备
    handleCheck(record) {
      this.$message.success('选择成功')
      this.$emit('selectFacilityData', record)
    },
    // 获取部门树数据
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
  },
}
</script>

<style scoped>
.content-right {
  border-left: 1px solid rgba(233, 233, 233, 1);
}
</style>
